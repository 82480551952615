.panel {
    background-color: #FFF;
    border-radius: 5px;
    border: 1px solid var(--panel-border);
    padding: 15px;
    margin-bottom: 20px;
}

.subHeader {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--panel-header);
    margin-bottom: 15px;
}