.FeedbackIconContainer {
    position: relative;
    width: 100%;
}

.FeedbackIcon {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
}

.searchInput {
    padding-right: 30px;
    border-radius: 20px;
    background-color: #F9FAFA;
}

.searchInput:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none !important;
}

.resultsContainerPadding {
    position: absolute;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    z-index: 1;
}

@media (max-width: 768px) {
    .resultsContainerPadding {
        width: calc(100% + 30vw);
        margin-left: -15vw;
    }
}

.resultsContainer {
    width: 100%;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0px 3px 10px -4px rgba(0, 0, 0, 0.48);
    -webkit-box-shadow: 0px 3px 10px -4px rgba(0, 0, 0, 0.48);
    -moz-box-shadow: 0px 3px 10px -4px rgba(0, 0, 0, 0.48);
    padding: 10px;
}

.activeItem {
    background-color: var(--panel-border);
}