.formHeader {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: var(--seondary-text);
    margin-bottom: 15px;
}

@media (max-width: 1900px) {
    .formHeader {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 10px;
    }
}

.formBoldText {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--primary-text);
    margin-bottom: 5px;
}