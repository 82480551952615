.closeBtnContainer {
    display: flex;
    justify-content: end;
}

@media (max-width: 768px) {
    .closeBtnContainer {
        display: flex;
        justify-content: center;
    }
}