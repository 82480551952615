body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/* System colors */

:root {
    --bg-color-1: #F9FAFA;
    --bg-color-2: #EDF4FB;
    --primary: #4D96D9;
    --primary-light: #BCD7F1;
    --primary-dark: #4585c0;
    --primary-text: #151515;
    --seondary-text: #57657A;
    --neutral-text: #5C6875;
    --navbar-text: #2E3D4D;
    --navbar-item: #2E5A82;
    --navbar-top: #5C6875;
    --natural: #DEDEDE;
    --natural-dark: #b9b9b9;
    --required: #F34336;
    --panel-border: #C7D4DD;
    --panel-header: #737D89;
    --paciente-header-header: #1F3C57;
    --paciente-forms-subheader: #2E5A82;
    --success: #7FAE1B;
    --success-dark: #77a11a;
    --tab-border: #B9BEC4;
    --tab-border-active: #2E5A82;
}

.centrar {
    display: flex;
    justify-content: center;
}

@media (max-width: 768px) {
    .centrar-md {
        display: flex;
        justify-content: center;
    }
}


/* skeleton */

@keyframes skeletonTextAnimation {
    0% {
        color: hsl(0, 0%, 90%);
    }
    100% {
        color: hsl(0, 0%, 70%);
    }
}

.text-skeleton {
    color: rgba(98, 98, 98, 0.25);
}

.skeleton {
    animation: skeletonTextAnimation 1s linear infinite alternate;
}


/* skeleton */