.customButton {
    display: flex;
    align-items: center;
    justify-content: center;
}

.customRoundedButton {
    border-radius: 100% !important;
    padding: 5px !important;
}

.customIconButton {
    border-radius: 100% !important;
    padding: 5px !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.customIconButtonNoPadding {
    border-radius: 100% !important;
    padding: 0px !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.customButtonMT {
    margin-top: 15px;
}

@media (max-width: 1900px) {
    .customButtonMT {
        margin-top: 10px;
    }
}

.customButtonML {
    margin-left: 15px;
}

.customButtonMR {
    margin-right: 15px;
}

.customPrimaryButton {
    font-weight: 500;
    color: #FFF;
    background-color: var(--primary);
    border-color: var(--primary);
}

.customPrimaryButton:hover {
    font-weight: 500;
    color: #FFF;
    background-color: var(--primary-dark);
    border-color: var(--primary-dark);
}

.customPrimaryButtonInverse {
    font-weight: 500;
    color: var(--primary);
    background-color: #FFF;
    border-color: var(--primary);
}

.customPrimaryButtonInverse:hover {
    font-weight: 500;
    color: var(--primary-dark);
    background-color: #FFF;
    border-color: var(--primary-dark);
}

.customLightButton {
    font-weight: 500;
    color: var(--neutral-text);
    background-color: var(--natural);
    border-color: var(--natural);
}

.customLightButton:hover {
    font-weight: 500;
    color: var(--neutral-text);
    background-color: var(--natural-dark);
    border-color: var(--natural-dark);
}

.customLightButtonInverse {
    font-weight: 500;
    color: var(--neutral-text);
    background-color: #FFF;
    border-color: var(--natural);
}

.customLightButtonInverse:hover {
    font-weight: 500;
    color: var(--neutral-text);
    background-color: #FFF;
    border-color: var(--natural-dark);
}

.customSuccessButton {
    font-weight: 500;
    color: #FFF;
    background-color: var(--success);
    border-color: var(--success);
}

.customSuccessButton:hover {
    font-weight: 500;
    color: #FFF;
    background-color: var(--success-dark);
    border-color: var(--success-dark);
}

.customSuccessButtonInverse {
    font-weight: 500;
    color: var(--success);
    background-color: #FFF;
    border-color: var(--success);
}

.customSuccessButtonInverse:hover {
    font-weight: 500;
    color: var(--success-dark);
    background-color: #FFF;
    border-color: var(--success-dark);
}

.customPrimarySmallButton {
    font-weight: 500;
    color: var(--primary);
    background-color: #FFF;
    border-color: var(--primary);
    border-width: 2px;
}

.customPrimarySmallButton:hover {
    font-weight: 500;
    color: var(--primary-dark);
    background-color: #FFF;
    border-color: var(--primary-dark);
    border-width: 2px;
}

.customPrimarySmallButtonInverse {
    font-weight: 500;
    color: #FFF;
    background-color: var(--primary);
    border-color: var(--primary);
    border-width: 2px;
}

.customPrimarySmallButtonInverse:hover {
    font-weight: 500;
    color: #FFF;
    background-color: var(--primary-dark);
    border-color: var(--primary-dark);
    border-width: 2px;
}

.customButtonBlock {
    width: 100%;
}

.icon {
    font-size: 30px;
}

.smallIcon {
    font-size: 20px;
}