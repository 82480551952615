.contentColumn {
    height: calc(100vh - 144px);
    overflow: auto;
}

.sideContainer {
    background-color: var(--bg-color-1);
    border-right: solid 1px var(--natural);
    padding: 20px;
    height: calc(100vh - 144px);
    overflow: auto;
}

@media (max-width: 767px) {
    .sideContainer {
        display: none;
    }
}

.header {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 24px;
    line-height: 28.13px;
    color: var(--primary);
    margin-bottom: 15px;
}

@media (max-width: 1023px) {
    .header {
        font-size: 22px;
        line-height: 26.13px;
    }
}

.sideItem {
    padding: 7px 15px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-radius: 3px;
}

@media (max-width: 1023px) {
    .sideItem {
        font-size: 12px;
        line-height: 18px;
    }
}

.sideItem:hover {
    background-color: var(--bg-color-2);
}

.sideItem a {
    text-decoration: none;
    color: var(--primary-text) !important;
}

.sideItemActive {
    background-color: var(--primary);
}

.sideItemActive:hover {
    background-color: var(--primary);
}

.sideItemActive a {
    text-decoration: none;
    color: #FFF !important;
}

.sideItemContainer {
    display: flex;
    align-items: center;
}

.sideIcon {
    background-size: cover;
    height: 16px;
    width: 16px;
    margin-right: 3px;
}

.sideIconPerfilBlack {
    background-image: url('/public/img/configuracion_perfil_icon_black.svg');
}

.sideIconPerfilWhite {
    background-image: url('/public/img/configuracion_perfil_icon_white.svg');
}

.sideIconConsultorioBlack {
    background-image: url('/public/img/configuracion_consultorio_icon_black.svg');
}

.sideIconConsultorioWhite {
    background-image: url('/public/img/configuracion_consultorio_icon_white.svg');
}

.sideIconExpedienteClinicoBlack {
    background-image: url('/public/img/configuracion_expediente_clinico_icon_black.svg');
}

.sideIconExpedienteClinicoWhite {
    background-image: url('/public/img/configuracion_expediente_clinico_icon_white.svg');
}

.sideIconAsistentesBlack {
    background-image: url('/public/img/configuracion_asistente_icon_black.svg');
}

.sideIconAsistentesWhite {
    background-image: url('/public/img/configuracion_asistente_icon_white.svg');
}

.sideIconHonorariosBlack {
    background-image: url('/public/img/configuracion_honorarios_icon_black.svg');
}

.sideIconHonorariosWhite {
    background-image: url('/public/img/configuracion_honorarios_icon_white.svg');
}

.sideIconCFDIBlack {
    background-image: url('/public/img/configuracion_cfdi_icon_black.svg');
}

.sideIconCFDIWhite {
    background-image: url('/public/img/configuracion_cfdi_icon_white.svg');
}