.navbarLogo {
    background-image: url('/public/img/Logo_slim.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    height: 35px;
    width: 180px;
}

.mainContainer {
    min-height: calc(100vh - 72px);
    padding-top: 72px;
}