.FeedbackIconContainer {
    position: relative;
}

.FeedbackIcon {
    position: absolute;
    left: 5px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
}

.searchInput {
    padding-left: 25px;
    border-radius: 0px;
    border-top: none;
    border-left: none;
    border-right: none;
}

.searchInput:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none !important;
}