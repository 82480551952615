.panel {
    background-color: #FFF;
    border-radius: 5px;
    border: 1px solid var(--panel-border);
    padding: 15px;
    margin-bottom: 20px;
}

.panelNoHeader {
    margin-top: 20px;
}

.header {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 38px;
    line-height: 44px;
    color: var(--primary);
    margin-bottom: 15px;
}

@media (max-width: 1023px) {
    .header {
        font-size: 28px;
        line-height: 34px;
    }
}

.panelSection {
    margin-bottom: 15px;
}

.subHeader {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 24px;
    line-height: 28.13px;
    color: var(--paciente-forms-subheader);
}

@media (max-width: 1023px) {
    .subHeader {
        font-size: 22px;
        line-height: 26.13px;
    }
}