.customInputBasic {
    font-family: 'Roboto';
    font-weight: 400;
    color: var(--primary-text);
    border: 1px solid var(--natural);
    margin-bottom: 15px;
}

@media (max-width: 1900px) {
    .customInputBasic {
        margin-bottom: 10px;
    }
}

.customInputBasic:focus {
    box-shadow: none !important;
}