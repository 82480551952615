.timeInputsContainerDisplay {
    display: flex;
    justify-content: start;
}

@media (max-width: 768px) {
    .timeInputsContainerDisplay {
        display: block;
        justify-content: unset;
    }
}