.navbar {
    background-color: #FFF !important;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
}

.navbarContainerFluid {
    display: block !important;
}

@media (max-width: 767px) {
    .navbarContainerFluid {
        display: flex !important;
    }
}

.navbarWeb {
    display: flex;
}

@media (max-width: 767px) {
    .navbarWeb {
        display: none;
    }
}

.navbarMovil {
    display: none;
}

@media (max-width: 767px) {
    .navbarMovil {
        display: block;
    }
}

.navbarLogo {
    background-image: url('/public/img/Logo_azul_small.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    height: 35px;
    width: 180px;
}

.navbarDropdownContainer {
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbarUserImg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    margin-right: 10px;
}

.navbarDropdown {
    margin-right: 5px;
}

.navbarDropdownButton {
    padding: 0px !important;
    margin-right: 15px;
}

.navbarDropdownButton:active {
    border-color: transparent !important;
}

.navbarDropdown button {
    border-color: transparent !important;
}

.navbarDropdownMenu {
    padding: 10px;
    left: unset !important;
    right: 0px !important;
}

.navbarDropdownSubMenu {
    padding: 10px;
    left: unset !important;
    right: 0px !important;
    border: none !important;
}

.navbarDropdownTop {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.navbarDropdownHeader {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 24px;
    line-height: 28.13px;
    color: var(--navbar-top);
}

.navbarDropdownSubHeader {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--navbar-top);
    white-space: nowrap;
}

.navbarDropdownMenuItem {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: var(--navbar-item);
}

.navbarDropdownMenuItem:hover {
    color: var(--primary);
}

.navbarDropdownMenuItem a {
    color: unset !important;
    text-decoration: none !important;
}

.navbarDropdownMenuItem a div {
    padding: 5px !important;
}

.navbarDropdownMenuItem a div:hover {
    color: unset !important;
    background-color: unset !important;
}

.navbarDropdownMenuSubItem {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: var(--navbar-top);
}

.navbarDropdownMenuSubItem a {
    color: unset !important;
    text-decoration: none !important;
}

.navbarDropdownMenuSubItem a div {
    padding: 5px !important;
}

.navbarDropdownMenuSubItem a div:hover {
    color: unset !important;
    background-color: unset !important;
}

.navbarDropdownMovilButton {
    padding: 10px 0px 0px 0px !important;
    margin-right: 15px;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: var(--navbar-item);
}

.navbarDropdownMovilButton:active {
    border-color: transparent !important;
}

.navbarDropdownClose {
    color: var(--primary);
    font-size: 50px;
    margin-left: 100px;
    cursor: pointer;
}

.logoutIconContainer {
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoutIcon {
    color: var(--seondary-text);
    font-size: 24px;
    cursor: pointer;
}

.navbarLogoutMovil {
    padding-top: 10px;
}

.navbarTab {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 5px;
    margin-right: 5px;
    color: var(--navbar-text);
}

.navbarTab:hover {
    background-color: var(--bg-color-2);
}

.navbarTabActive {
    background-color: var(--primary);
    color: #FFF;
}

.navbarTabActive:hover {
    background-color: var(--primary);
}

.navbarTabIcon {
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    height: 16px;
    width: 16px;
    margin-right: 5px;
}

.navbarTabIcon1 {
    background-image: url('/public/img/agenda_icon_gray.svg');
}

.navbarTabIcon11 {
    background-image: url('/public/img/agenda_icon_white.svg');
}

.navbarTabIcon2 {
    background-image: url('/public/img/inmunoterapias_icon_gray.svg');
}

.navbarTabIcon21 {
    background-image: url('/public/img/inmunoterapias_icon_white.svg');
}

.navbarTabIcon3 {
    background-image: url('/public/img/pacientes_icon_gray.svg');
}

.navbarTabIcon31 {
    background-image: url('/public/img/pacientes_icon_white.svg');
}