.customLink {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 15px;
    display: inline-block;
}

@media (max-width: 1900px) {
    .customLink {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}