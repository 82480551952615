.icon {
    color: var(--natural-dark);
    font-size: 30px;
}

.text {
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 22px;
    color: var(--natural-dark);
    text-align: center;
}