.modalHeader {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 38px;
    line-height: 44px;
    color: var(--primary);
    margin-top: 20px;
}

.modalSubHeader {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--natural-dark);
    margin-bottom: 20px;
}

.modalThirdHeader {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: var(--paciente-forms-subheader);
    margin-bottom: 15px;
}

.modalFourthHeader {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--paciente-forms-subheader);
    margin-bottom: 15px;
}

.helperErrorText {
    display: block;
    margin-top: .25rem;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: .825em;
    color: var(--required);
    margin-bottom: 20px;
}

.divider {
    background-color: var(--primary);
    height: 1px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 25px;
}