.header {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: var(--primary);
    margin: 15px 0px;
}

.tabsContainer {
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
}

.tab {
    padding: 8px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    color: var(--panel-header);
    border-bottom: solid 4px var(--tab-border);
    cursor: pointer;
}

.tabActive {
    background-color: var(--primary);
    padding: 8px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    color: #FFF;
    padding: 8px;
    border-bottom: solid 4px var(--tab-border-active);
    cursor: pointer;
}

.tabsContentContainer {
    margin-top: 15px;
}