.tableHeader {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 24px;
    line-height: 28.13px;
    color: var(--paciente-forms-subheader);
    margin-bottom: 15px;
}

@media (max-width: 1023px) {
    .tableHeader {
        font-size: 22px;
        line-height: 26.13px;
    }
}

.panel {
    height: 100%;
    background-color: #FFF;
    border-radius: 1px;
    border: 1px solid var(--panel-border);
    padding: 15px;
}

@media (max-width: 768px) {
    .panel {
        height: unset;
        margin-bottom: 15px;
    }
}

.panelNoHeader {
    margin-top: 20px;
}

.panelSection {
    margin-bottom: 15px;
}

.subHeader {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 24px;
    line-height: 28.13px;
    color: var(--paciente-forms-subheader);
}

.filterContainer {
    display: flex;
    justify-content: end;
}

@media (max-width: 768px) {
    .filterContainer {
        display: flex;
        justify-content: center;
    }
}

.tableDropdownButton {
    padding: 0px !important;
    margin-right: 15px;
}

.tableDropdownButton:active {
    border-color: transparent !important;
}

.tableDropdown button {
    border-color: transparent !important;
}

.tableDropdownItem {
    cursor: pointer;
}