.customModalBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1032;
}

.customModalNoBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1032;
}

.customModalPanel {
    width: fit-content;
    height: fit-content;
    background-color: #FFF;
    padding: 15px;
    border-radius: 1px;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
    max-height: 100%;
    overflow: auto;
}