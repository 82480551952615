.closeBtnContainer {
    display: flex;
    justify-content: center;
}

@media (max-width: 768px) {
    .closeBtnContainer {
        display: flex;
        justify-content: center;
    }
}

.addBtnContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aplicacionesContainerScroll {
    width: 100%;
    overflow-x: auto;
}

.aplicacionesContainer {
    display: flex;
    width: fit-content;
}

.aplicacionContainer {
    width: 150px;
}