.authContainer {
    background: linear-gradient(180deg, var(--bg-color-1) 0%, var(--bg-color-2) 100%);
}

.ilustracionContainer {
    background-image: url('/public/img/Ilustracion.png');
    background-size: 65%;
    background-repeat: no-repeat;
    background-position-x: 85%;
    background-position-y: bottom;
    min-height: 100vh;
}

.elipseContainerSmall {
    background-image: url('/public/img/Ellipse.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: center;
    min-height: 100vh;
}

@media (max-width: 1899px) {
    .ilustracionContainer {
        background-position-x: 90%;
    }
}

@media (max-width: 1500px) {
    .ilustracionContainer {
        background-position-x: 95%;
    }
}

@media (max-width: 1199px) {
    .ilustracionContainer {
        background-size: 60%;
        background-position-x: center;
    }
    .elipseContainerSmall {
        display: none;
    }
}

@media (max-width: 1500px) {
    .ilustracionContainer {
        background-image: none;
    }
}

.formContainer {
    padding-left: 5rem;
    padding-right: 5rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

@media (max-width: 1900px) {
    .formContainer {
        padding: 3rem;
    }
}

.formLogo {
    background-image: url('/public/img/Logo_azul_small.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 70px;
    width: 360px;
    margin-top: 15px;
    margin-bottom: 30px;
}