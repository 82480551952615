.header {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 24px;
    line-height: 28.13px;
    color: var(--paciente-forms-subheader);
}

@media (max-width: 1023px) {
    .header {
        font-size: 22px;
        line-height: 26.13px;
    }
}

.subHeader {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--paciente-forms-subheader);
    margin-bottom: 15px;
}

.data {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 15px;
}