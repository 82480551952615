.contentColumn {
    height: calc(100vh - 144px);
    overflow: auto;
}

.sideContainer {
    background-color: #FFF;
    border-right: solid 1px var(--natural);
    padding: 20px;
    height: calc(100vh - 144px);
    overflow: auto;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
}

@media (max-width: 767px) {
    .sideContainer {
        display: none;
    }
}