.panel {
    background-color: #FFF;
    border-radius: 5px;
    border: 1px solid var(--panel-border);
    padding: 15px;
    margin-bottom: 20px;
}

.panelNoHeader {
    margin-top: 20px;
}

.pacientesHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 1023px) {
    .pacientesHeaderContainer {
        display: block;
    }
}

.pacienteHeaderTabsContainer {
    display: flex;
    align-items: center;
}

@media (max-width: 1023px) {
    .pacienteHeaderTabsContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
}

.pacientesHeaderTabContainer {
    min-width: 114px;
    margin-left: 8px;
    margin-right: 8px;
    cursor: pointer;
}

@media (max-width: 1900px) {
    .pacientesHeaderTabContainer {
        min-width: 50px;
    }
}

@media (max-width: 1199px) {
    .pacientesHeaderTabContainer {
        min-width: 30px;
    }
}

@media (max-width: 767px) {
    .pacientesHeaderTabContainer {
        margin-left: 5px;
        margin-right: 5px;
    }
}

.pacientesHeaderTabIcon {
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    height: 32px;
    width: 32px;
    margin: 2px;
}

@media (max-width: 1900px) {
    .pacientesHeaderTabIcon {
        height: 28px;
        width: 28px;
    }
}

@media (max-width: 1023px) {
    .pacientesHeaderTabIcon {
        height: 32px;
        width: 32px;
    }
}

@media (max-width: 767px) {
    .pacientesHeaderTabIcon {
        height: 28px;
        width: 28px;
    }
}

.pacientesHeaderTabIconInicio {
    background-image: url('/public/img/tab_paciente_inicio_icon.svg');
}

.pacientesHeaderTabContainer:hover>div>.pacientesHeaderTabIconInicio {
    background-image: url('/public/img/tab_paciente_inicio_icon_hover.svg');
}

.pacientesHeaderTabIconInicioActive {
    background-image: url('/public/img/tab_paciente_inicio_icon_active.svg');
}

.pacientesHeaderTabIconConsulta {
    background-image: url('/public/img/tab_paciente_consulta_icon.svg');
}

.pacientesHeaderTabContainer:hover>div>.pacientesHeaderTabIconConsulta {
    background-image: url('/public/img/tab_paciente_consulta_icon_hover.svg');
}

.pacientesHeaderTabIconConsultaActive {
    background-image: url('/public/img/tab_paciente_consulta_icon_active.svg');
}

.pacientesHeaderTabIconCitas {
    background-image: url('/public/img/tab_paciente_citas_icon.svg');
}

.pacientesHeaderTabContainer:hover>div>.pacientesHeaderTabIconCitas {
    background-image: url('/public/img/tab_paciente_citas_icon_hover.svg');
}

.pacientesHeaderTabIconCitasActive {
    background-image: url('/public/img/tab_paciente_citas_icon_active.svg');
}

.pacientesHeaderTabIconInmunoterapias {
    background-image: url('/public/img/tab_paciente_inmunoterapias_icon.svg');
}

.pacientesHeaderTabContainer:hover>div>.pacientesHeaderTabIconInmunoterapias {
    background-image: url('/public/img/tab_paciente_inmunoterapias_icon_hover.svg');
}

.pacientesHeaderTabIconInmunoterapiasActive {
    background-image: url('/public/img/tab_paciente_inmunoterapias_icon_active.svg');
}

.pacientesHeaderTabIconPruebasAlergia {
    background-image: url('/public/img/tab_paciente_pruebas_alergia_icon.svg');
}

.pacientesHeaderTabContainer:hover>div>.pacientesHeaderTabIconPruebasAlergia {
    background-image: url('/public/img/tab_paciente_pruebas_alergia_icon_hover.svg');
}

.pacientesHeaderTabIconPruebasAlergiaActive {
    background-image: url('/public/img/tab_paciente_pruebas_alergia_icon_active.svg');
}

.pacientesHeaderTabIconAseguradoras {
    background-image: url('/public/img/tab_paciente_aseguradoras_icon.svg');
}

.pacientesHeaderTabContainer:hover>div>.pacientesHeaderTabIconAseguradoras {
    background-image: url('/public/img/tab_paciente_aseguradoras_icon_hover.svg');
}

.pacientesHeaderTabIconAseguradorasActive {
    background-image: url('/public/img/tab_paciente_aseguradoras_icon_active.svg');
}

.pacientesHeaderTabIconAntecedentes {
    background-image: url('/public/img/tab_paciente_antecedentes_icon.svg');
}

.pacientesHeaderTabContainer:hover>div>.pacientesHeaderTabIconAntecedentes {
    background-image: url('/public/img/tab_paciente_antecedentes_icon_hover.svg');
}

.pacientesHeaderTabIconAntecedentesActive {
    background-image: url('/public/img/tab_paciente_antecedentes_icon_active.svg');
}

.pacientesHeaderTabIconEscalasControl {
    background-image: url('/public/img/tab_paciente_escalas_control_icon.svg');
}

.pacientesHeaderTabContainer:hover>div>.pacientesHeaderTabIconEscalasControl {
    background-image: url('/public/img/tab_paciente_escalas_control_icon_hover.svg');
}

.pacientesHeaderTabIconEscalasControlActive {
    background-image: url('/public/img/tab_paciente_escalas_control_icon_active.svg');
}

.pacientesHeaderTabIconHistorial {
    background-image: url('/public/img/tab_paciente_historial_icon.svg');
}

.pacientesHeaderTabContainer:hover>div>.pacientesHeaderTabIconHistorial {
    background-image: url('/public/img/tab_paciente_historial_icon_hover.svg');
}

.pacientesHeaderTabIconHistorialActive {
    background-image: url('/public/img/tab_paciente_historial_icon_active.svg');
}

.pacientesHeaderTabIconOpciones {
    background-image: url('/public/img/tab_paciente_opciones_icon.svg');
}

.pacientesHeaderTabContainer:hover .pacientesHeaderTabText {
    color: #9AC3EA;
}

.pacientesHeaderTabText {
    text-align: center;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--panel-header);
}

.pacientesHeaderTabTextActive {
    text-align: center;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--primary) !important;
}

@media (max-width: 1900px) {
    .pacientesHeaderTabText {
        font-size: 12px;
        line-height: 18px;
    }
    .pacientesHeaderTabTextActive {
        font-size: 12px;
        line-height: 18px;
    }
}

@media (max-width: 767px) {
    .pacientesHeaderTabText {
        display: none;
    }
    .pacientesHeaderTabTextActive {
        display: none;
    }
}

.pacientesHeaderDropdownItem {
    padding: 5px 10px;
}

.pacientesHeaderUserDataContainer {
    display: flex;
    align-items: center;
}

.pacientesHeaderUserImg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    height: 93px;
    width: 93px;
    border-radius: 100%;
    margin-right: 10px;
}

@media (max-width: 1900px) {
    .pacientesHeaderUserImg {
        height: 80px;
        width: 80px;
    }
}

@media (max-width: 767px) {
    .pacientesHeaderUserImg {
        height: 60px;
        width: 60px;
    }
}

.pacientesHeaderNoExpediente {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: var(--primary);
}

@media (max-width: 1900px) {
    .pacientesHeaderNoExpediente {
        font-size: 18px;
        line-height: 22px;
    }
}

@media (max-width: 767px) {
    .pacientesHeaderNoExpediente {
        font-size: 12px;
        line-height: 16px;
    }
}

.pacientesHeaderNombre {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 38px;
    line-height: 44px;
    color: var(--paciente-header-header);
}

@media (max-width: 1900px) {
    .pacientesHeaderNombre {
        font-size: 34px;
        line-height: 40px;
    }
}

@media (max-width: 1199px) {
    .pacientesHeaderNombre {
        font-size: 30px;
        line-height: 36px;
    }
}

@media (max-width: 767px) {
    .pacientesHeaderNombre {
        font-size: 20px;
        line-height: 26px;
    }
}

.pacientesHeaderEdad {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: var(--seondary-text);
}

@media (max-width: 1900px) {
    .pacientesHeaderEdad {
        font-size: 20px;
        line-height: 24px;
        color: var(--seondary-text);
    }
}

@media (max-width: 1199px) {
    .pacientesHeaderEdad {
        font-size: 16px;
        line-height: 20px;
    }
}

@media (max-width: 767px) {
    .pacientesHeaderEdad {
        font-size: 14px;
        line-height: 18px;
    }
}

.pacientesHeaderEditBtn {
    font-size: 18px;
    color: var(--paciente-header-header);
    cursor: pointer;
    margin-left: 5px;
}

.badge {
    display: flex;
    justify-content: center;
    align-items: center;
}