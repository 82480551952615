.contentColumn {
    height: calc(100vh - 144px);
    overflow: auto;
}

@media (max-width: 767px) {
    .contentColumn {
        height: unset;
    }
}

.sideContainer {
    background-color: #FFF;
    border-right: solid 1px var(--natural);
    padding: 20px;
    height: calc(100vh - 144px);
    overflow: auto;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
}

@media (max-width: 767px) {
    .sideContainer {
        /* display: none; */
        height: unset;
    }
}

.sideContainerDisplay {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.switch:checked {
    background-color: var(--primary);
    border-color: var(--primary);
}