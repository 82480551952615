.button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    color: var(--primary);
    border-color: var(--primary);
    cursor: pointer;
}

.button:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    color: var(--primary-dark);
    border-color: var(--primary-dark);
    cursor: pointer;
}

.btnDisabled {
    background-color: var(--natural) !important;
    color: #FFF !important;
}

.btnDisabled:hover {
    background-color: var(--natural) !important;
    color: #FFF !important;
    cursor: not-allowed !important;
}

.ml {
    margin-left: 5px;
}

.mr {
    margin-right: 5px;
}