.formHeader {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 38px;
    line-height: 51px;
    color: var(--primary-text);
    margin-bottom: 15px;
}

@media (max-width: 1900px) {
    .formHeader {
        font-size: 32px;
        line-height: 46px;
        margin-bottom: 10px;
    }
}

.formText {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--primary-text);
}

.formBoldText {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--primary-text);
    margin-bottom: 5px;
}

.registerNavigationContainer {
    margin-top: 15px;
    margin-bottom: 15px;
}

@media (max-width: 1900px) {
    .registerNavigationContainer {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}