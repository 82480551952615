.logoContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.logo {
    background-image: url('/public/img/Logo_azul_small.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    height: 70px;
    width: 360px;
    margin-top: 20px;
    margin-bottom: 20px;
}