.header {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: var(--primary);
    margin: 15px 0px;
}

.panel {
    background-color: #FFF;
    border-radius: 5px;
    border: 1px solid var(--panel-border);
    padding: 15px;
    margin-bottom: 20px;
}

.panelSection {
    margin-bottom: 15px;
}

.subHeader {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--panel-header);
}