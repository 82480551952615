.header {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: var(--primary);
    margin: 15px 0px;
}

.panel {
    background-color: #FFF;
    border-radius: 5px;
    border: 1px solid var(--panel-border);
    padding: 15px;
}

.panelSection {
    margin-bottom: 15px;
}

.subHeader {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--panel-header);
}

.userImg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 160px;
    height: 160px;
    border-radius: 100%;
}

@media (max-width: 1199px) {
    .userImg {
        width: 140px;
        height: 140px;
    }
}