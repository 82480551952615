.header {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: var(--primary);
    margin: 15px 0px;
}

.consultorioNombre {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: var(--navbar-item);
    margin: 15px 0px;
}

.subHeader {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--panel-header);
    margin-bottom: 15px;
}

.panel {
    background-color: #FFF;
    border-radius: 5px;
    border: 1px solid var(--panel-border);
    padding: 15px;
    margin-bottom: 20px;
}

.consultorioImg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 160px;
    height: 160px;
    border-radius: 100%;
}

@media (max-width: 1199px) {
    .consultorioImg {
        width: 140px;
        height: 140px;
    }
}

.previewLabel {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--seondary-text);
}

.tarjeta {
    background-color: #FFF;
    border-radius: 5px;
    border: 1px solid var(--panel-border);
    padding: 15px;
    margin-bottom: 20px;
}

.tarjetaHeader {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--navbar-item);
    margin-bottom: 15px;
}