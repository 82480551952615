.panel {
    background-color: #FFF;
    border-radius: 5px;
    border: 1px solid var(--panel-border);
    padding: 15px;
    margin-bottom: 20px;
}

.panelNoHeader {
    margin-top: 20px;
}

.nuevoPacienteBtnContainer {
    display: flex;
    justify-content: end;
}

@media (max-width: 768px) {
    .nuevoPacienteBtnContainer {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
    }
}

.filterContainer {
    margin-top: 15px;
    display: flex;
    justify-content: end;
}

@media (max-width: 768px) {
    .filterContainer {
        display: flex;
        justify-content: center;
    }
}

.header {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 24px;
    line-height: 28.13px;
    color: var(--paciente-forms-subheader);
    margin-bottom: 15px;
}

@media (max-width: 1023px) {
    .header {
        font-size: 22px;
        line-height: 26.13px;
    }
}