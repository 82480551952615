.panel {
    height: 100%;
    background-color: #FFF;
    border-radius: 1px;
    border: 1px solid var(--panel-border);
    padding: 15px;
}

@media (max-width: 768px) {
    .panel {
        height: unset;
        margin-bottom: 15px;
    }
}

.panelDivider {
    height: 100%;
    border-left: 1px solid var(--panel-border);
    padding-left: calc(var(--bs-gutter-x) * .5 * 2);
}

@media (max-width: 768px) {
    .panelDivider {
        height: unset;
        border-left: none;
        padding-left: 0px;
    }
}

.h-100 {
    height: 100%;
}

@media (max-width: 768px) {
    .h-100 {
        height: unset;
    }
}

.panelNoHeader {
    margin-top: 20px;
}

.header {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 38px;
    line-height: 44px;
    color: var(--primary);
    margin-bottom: 15px;
}

@media (max-width: 1023px) {
    .header {
        font-size: 28px;
        line-height: 34px;
    }
}

.panelHeader {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: var(--paciente-forms-subheader);
    margin-bottom: 15px;
}


/* @media (max-width: 1023px) {
    .panelHeader {
        font-size: 28px;
        line-height: 34px;
    }
} */

.panelSection {
    margin-bottom: 15px;
}

.subHeader {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 24px;
    line-height: 28.13px;
    color: var(--paciente-forms-subheader);
}

@media (max-width: 1023px) {
    .subHeader {
        font-size: 22px;
        line-height: 26.13px;
    }
}

@media (max-width: 768px) {
    .tutorDivider {
        border-bottom: 1px solid var(--panel-border);
        margin-bottom: 15px;
    }
}

.pacientesAsociadosHr {
    margin: 3px 0px;
    border-top: var(--bs-border-width) solid var(--panel-border);
}