.notificationsContainer {
    position: fixed;
    top: 160px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    max-width: 90%;
    z-index: 1;
}

@keyframes notificationsAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.notificationContainer {
    display: flex;
    justify-content: center;
    animation-name: notificationsAnimation;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
}

.notification {
    text-align: center;
    width: 65vw;
    margin-bottom: 10px;
}

@media (max-width: 767px) {
    .notification {
        width: 90vw;
    }
}