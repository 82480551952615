.tabsContainer {
    background-color: var(--bg-color-1);
    border-radius: 5px;
    border: 1px solid var(--panel-border);
    padding: 15px;
    margin-bottom: 20px;
}

.tabContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.tabInmunoterapias {
    background-image: url('/public/img/tab_consulta_inmunoterapias_icon.svg');
}

.tabInmunoterapias:hover {
    background-image: url('/public/img/tab_consulta_inmunoterapias_icon_hover.svg');
}

.tabInmunoterapiasActive {
    background-image: url('/public/img/tab_consulta_inmunoterapias_icon_active.svg');
}

.tabPruebas {
    background-image: url('/public/img/tab_consulta_pruebas_icon.svg');
}

.tabPruebas:hover {
    background-image: url('/public/img/tab_consulta_pruebas_icon_hover.svg');
}

.tabPruebasActive {
    background-image: url('/public/img/tab_consulta_pruebas_icon_active.svg');
}

.tabLaboratorios {
    background-image: url('/public/img/tab_consulta_laboratorios_icon.svg');
}

.tabLaboratorios:hover {
    background-image: url('/public/img/tab_consulta_laboratorios_icon_hover.svg');
}

.tabLaboratoriosActive {
    background-image: url('/public/img/tab_consulta_laboratorios_icon_active.svg');
}

.tabRadiologia {
    background-image: url('/public/img/tab_consulta_radiologia_icon.svg');
}

.tabRadiologia:hover {
    background-image: url('/public/img/tab_consulta_radiologia_icon_hover.svg');
}

.tabRadiologiaActive {
    background-image: url('/public/img/tab_consulta_radiologia_icon_active.svg');
}

.tabsSubHeader {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--paciente-forms-subheader);
}

.estudioContainer {
    margin-top: 15px;
}

.divider {
    width: 100%;
    height: 0.5px;
    background-color: var(--primary-light);
    margin-bottom: 1rem;
}

.tabsContainerInner {
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
}

.tabInner {
    padding: 8px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    color: var(--panel-header);
    border-bottom: solid 4px var(--tab-border);
    cursor: pointer;
}

.tabActiveInner {
    background-color: var(--primary);
    padding: 8px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    color: #FFF;
    padding: 8px;
    border-bottom: solid 4px var(--tab-border-active);
    cursor: pointer;
}

.tabsContentContainerInner {
    margin-top: 15px;
}

.hrLabRad {
    background-color: var(--primary);
    height: 1px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 25px;
}

.singleTopInmunoterapias {
    display: flex;
    justify-content: space-between;
}

.singleTopInmunoterapiasOptions {
    display: flex;
    justify-content: end;
}

.singleTopInmunoterapiasOptionsText {
    color: var(--primary) !important;
}

.tabsContainerInmuno {
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
}

.tabInmuno {
    padding: 8px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    color: var(--paciente-forms-subheader) !important;
    padding: 8px;
    cursor: pointer;
    border-radius: 20px;
    display: flex;
    justify-content: center;
}

.tabActiveInmuno {
    background-color: var(--primary);
    padding: 8px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    color: #FFF !important;
    padding: 8px;
    cursor: pointer;
    border-radius: 20px;
    display: flex;
    justify-content: center;
}

.tabsContentContainerInmuno {
    margin-top: 15px;
}

.tabInmunoDropdown {
    background-color: transparent !important;
    border: none;
    padding: 0px;
}

.tabInmunoDropdown:hover {
    background-color: transparent !important;
    border: none;
    padding: 0px;
}

.tabInmunoDropdown:active {
    background-color: transparent !important;
    border: none;
    padding: 0px;
}

.tabInmunoDropdown::after {
    display: none;
}

.tabInmunoDropdownText {
    margin-top: 2px;
}

.textTabInmuno button {
    color: var(--paciente-forms-subheader) !important;
}

.textTabActiveInmuno button {
    color: #FFF !important;
}

.dropdownItem {
    cursor: pointer;
}